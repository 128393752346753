.js-tooltip {
    @include box-sizing(border-box);

    display: none;
    background-color: $grey2;
    border-radius: 8px;
    padding: 20px 30px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    font-weight: $f-regular;
    z-index: -1;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      position: absolute;
      top: auto;
      left: -5px;
      background-color: $grey2;
  }

  &.is-visible {
      display: block;
      z-index: 900;
  }
}
