/* layout */
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin inline-list($justify-content: space-between) {
  display: flex;
  justify-content: $justify-content;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* icon mixin */

@mixin icon($icon-name, $bgwidth, $bgheight) {
        background-image: url(#{$icon-path}/#{$icon-name}.svg);
        background-size: $bgwidth $bgheight;
        width: $bgwidth;
        height: $bgheight;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
}

/* border-radius */

@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
}

@mixin border-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

/*box-shadow */

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
                box-shadow: $shadow;
}

/* box-model */

@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
                box-sizing: $boxmodel;
}

@mixin respond-to($breakpoint) {
    @media (max-width: $breakpoint + px) { @content; }

    //$mq: (min-width: $breakpoint + px);
    //@include respond-to($mq) {
    //    @content;
    //};
}

@mixin respond-to-min($breakpoint) {
    @media (min-width: $breakpoint + px) { @content; }

    //$mq: (min-width: $breakpoint + px);
    //@include respond-to($mq) {
    //    @content;
    //};
}

// Centering

@mixin multi-row-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  /* You can set flex-wrap and flex-direction individually */
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Or do it all in one line with flex flow */
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  /* tweak the where items line up on the row */
  /* valid values are: flex-start, flex-end, space-between, space-around, stretch */
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

@mixin multi-row-flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  /* You can set flex-wrap and flex-direction individually */
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Or do it all in one line with flex flow */
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  /* tweak the where items line up on the row */
  /* valid values are: flex-start, flex-end, space-between, space-around, stretch */
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

@mixin h-v-center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: row;
     flex-direction: row;
}

@mixin row {
  display: flex;
  -webkit-flex-direction: row;
     flex-direction: row;
}

@mixin column {
  display: flex;
  -webkit-flex-direction: column;
     flex-direction: column;
}

@mixin row-stretch {
  display: flex;
  align-items: stretch;
  -webkit-flex-direction: row;
     flex-direction: row;
}

@mixin v-center-row {
  display: flex;
  align-items: center;
  -webkit-flex-direction: row;
     flex-direction: row;
}

@mixin h-v-center-columns {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
     flex-direction: column;
}

@mixin h-v-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin v-center {
  top: 50%;
  -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
        -ms-transform: translateY(-50%); /* IE 9 */
            transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
}

@mixin h-center {
  left: 50%;
  -webkit-transform: translateX(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
        -ms-transform: translateX(-50%); /* IE 9 */
            transform: translateX(-50%); /* IE 10, Fx 16+, Op 12.1+ */
}

/* gradient-mixin 2 colors vertical */

@mixin gradient-vertical ($start-color, $end-color, $start-percent, $end-percent, $important : "") {
    @if $important != "" {
      background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)) $important; // Safari 4+, Chrome 2+
      background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent) $important; // Safari 5.1+, Chrome 10+
      background-image: -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent) $important; // FF 3.6+
      background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent) $important; // Standard, IE10
      background-repeat: repeat-x;
      background-color: $start-color $important; // since IE9 is not able to support linear gradients in combination with rounded borders, IE9 and below get a fixed color instead of gradients
    }
    background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // Safari 5.1+, Chrome 10+
    background-image: -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
    background-repeat: repeat-x;
    background-color: $start-color; // since IE9 is not able to support linear gradients in combination with rounded borders, IE9 and below get a fixed color instead of gradients
}