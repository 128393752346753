.search {
	@include row;
	margin-bottom: 10px;

	.input-with-autocomplete {
		@include box-sizing(border-box);
		width: calc(100% - 111px);
		margin-right: 10px;
		position: relative;
	}

	.searchfield {
		border: none;
		padding: 13px;
		font-size: 16px;
		font-weight: $f-bold;
		color: $grey1;
		background-color: #ffffff;
		border-radius: 6px;

		&::placeholder {
			font-style: italic;
			color: $placeholder;
		}
	}

	@include respond-to(786) {
		margin: 0 2% 10px;
	}
}

.most_searched {
	margin-bottom: 20px;

	&__headline {
		color: $white;
		font-weight: $f-bold;
		font-size: 16px;
		margin-bottom: 5px;
		display: inline-block;
	}

	&__list {
		padding: 0;
		margin: 0;
		display: inline-block;
	}

	@include respond-to(786) {
		margin: 0 2% 20px;
	}
}

.search-item {
	display: inline-block;
	background-color: $red;
	border: 2px solid transparent;
	color: $white;
	border-radius: 4px;
	font-size: 16px;
	font-weight: $f-regular;
	padding: 8px;
	margin-left: 5px;
	margin-bottom: 5px;

	&:hover {
		transition: background-color 20ms ease-out;
		background-color: darken($red, 5%);
	}

	&.is-selected {
		border: 2px solid darkred;
	}

	a {
		text-decoration: none;
		color: $white;
	}
}

#js-suggestions {
	display: none;
}

.suggest-items {
	position: absolute;
	border-radius: 4px 4px 6px 6px;
	margin-top: 1px;
	padding: 10px 5px;
	top: 100%;
	left: 0;
	right: 0;
	background-color: #dedede;
	z-index: 100;
	box-shadow: 0 6px 20px rgba(0, 0, 0, .5);
}
