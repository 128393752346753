.mobile-nav__trigger {
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: 901;
    margin-top: 20px;
    margin-left: 20px;
    top: 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    &-element {
        display: inline-block;
        position: absolute;
        height: 7px;
        width: 25px;
        background-color: $red;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        @include respond-to(762) {
            background-color: $grey2;
        }
    }

    :nth-child(1) {
        top: 0;
    }

    :nth-child(2) {
        top: 9px;
    }

    :nth-child(3) {
        top: 18px;
    }
}

.mobile-nav {
    position: absolute;
    width: 312px;
    background-color: $grey2;
    z-index: 99;
    display: none;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    @include respond-to(786) {
        position: fixed;
        height: 100%;
        box-shadow: none;
    }

    .mobile-nav__list {
        margin-top: 60px;
        list-style: none;
        padding-left: 20px;

        li {
            display: inline-block;
            width: 100%;

            a {
                box-sizing: border-box;
                text-transform: uppercase;
                padding-bottom: 15px;
                padding-top: 15px;
                color: $grey1;
                text-decoration: none;
                width: 100%;
                display: inline-block;
            }
        }
    }

    .mobile-nav__list--1 > li > a {
        font-weight: $f-extrabold;
        font-size: 18px;
        color: $grey1;
    }

    .mobile-nav__list--2 {
        background-color: rgba(0,0,0,0.1);
        margin-left: -20px;
        margin-top: 0;
        padding-left: 0;
        padding-top: 5px;
        padding-bottom: 5px;

        li {
            padding-bottom: 0;

            a {
                text-transform: none;
                display: inline-block;
                width: 100%;
                padding: 10px 0 10px 35px;
                font-size: 14px;
                font-weight: $f-semibold;
            }
        }
    }

    .mobile-nav-list li:hover .submenu {
        display: block;
    }
}

.submenu {
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s ease-out;
}

.mobile-nav-open {
    opacity: 1.0 !important;
    display: inline-block;

    & + .header {
        .mobile-nav__trigger {
            @include respond-to(786) {
                position: fixed;
                top: 0;

                .mobile-nav__trigger-element {
                    background-color: $red;
                }
            }

            :nth-child(1) {
                top: 18px;
                width: 0;
                height: 0;
            }

            :nth-child(2) {
                transform: rotate(45deg);
                top: 9px;
            }

            :nth-child(3) {
                transform: rotate(-45deg);
                top: 9px;
            }
        }
    }
}
