.rich-text {
    @include clearfix;

    h2,
    h3,
    h4 {
      margin-bottom: 0;
      color: $red;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }

    p {
        @include clearfix;

        margin-bottom: 0;
        line-height: 1.5;

        &.left {
            @include respond-to-min(960) {
                float: left;
            }
        }

        &.right {
            @include respond-to-min(960) {
                float: right;
            }
        }
    }
}

img.richtext-image {
    &.left {
        @include respond-to-min(960) {
            float: left;
            padding-right: 20px;
        }
    }

    &.right {
        @include respond-to(960) {
            float: right;
            padding-left: 20px;
        }
    }

    &.full-width,
    &.half-width {
        margin: auto;
    }
}
