.pagination {
    margin-top: 30px;

    &_list {
        @include inline-list;

        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    &__item {
        @include flex-center;

        $x: 20px;

        border: 3px solid $red;
        width: $x;
        height: $x;
        text-align: center;
        line-height: $x;
        margin: 3px;
        font-size: 14px;

        a {
            display: block;
            width: 100%;
            font-weight: bold;
            color: $red;
        }

        &.is-active {
            background-color: $red;
            border-color: $red;

            a {
                cursor: default;
                color: white;
            }
        }

        &:hover {
            background-color: $red;

            a {
               color: white;
            }
        }
    }
}
