%button {
	background-color: $red;
	color: $white;
	border: 0;
	font-weight: $f-bold;
	font-size: 15px;
	text-transform: uppercase;
}

.btn-search {
	@extend %button;

	width: 101px;
	float: right;
	display: inline-block;
	margin-top: 0 !important;
	border-radius: 6px;
}

.btn-holder {
	width: 90%;
    text-align: center;

	.btn-continue{
		border:2px solid $red;
		border-radius: 0;
		color: $red;
	}
}

.btn--readmore {
	border: 4px solid $red;
	display: inline-block;
	color: $red;
	font-weight: $f-semibold;
	padding: 13px 20px;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 13px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 10px;
	border-radius: 6px;
}

.js-back-button {
	opacity: 0;
	transition: opacity 100ms ease-in-out;

	&.is-visible {
		opacity: 1;
	}
}

.back-button {
	@extend %button;

	padding: 5px 15px;
	display: inline-block;
    margin: 0 0 10px;

	&:before {
		content: '«';
		padding-right: 7px;
		margin-top: -1px;
	}
}