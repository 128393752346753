/*
    Declare global variables here
*/

$icon-path: "/static/theme/img/icons";

// Colors
$red: #E4003A;
$dark-red: darken($red, 20%);
$grey1: #3A3A3A;
$grey2: #D8D8D8;
$white: #FFFFFF;
$placeholder: #9B9B9B;

$c-success: #118811;
$c-error: $red;
$c-info: #dd8800;

// Fonts
$f-body: 'Open Sans', sans-serif;

// Font weights
$f-regular: 400;
$f-semibold: 600;
$f-bold: 700;
$f-extrabold: 800;

// z-indexes
// usage: "z-index: z($elements, header);"
// for an additional z-index, just add to the list, starting with value '1';
