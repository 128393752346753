%icon-link {
	padding-left: 25px;
	background-repeat: no-repeat;
	color: $red;
}

.link-tel {
	@extend %icon-link;
	background-image: url('#{$icon-path}/phone.png');
	background-size: 10px 16px;
	background-position: 3px center;
}

.link-fax {
	@extend %icon-link;
	background-image: url('#{$icon-path}/fax.png');
	background-size: 16px 16px;
	background-position: left center;
}

.link-mail {
	@extend %icon-link;
	background-image: url('#{$icon-path}/mail-2.png');
	background-size: 16px 10px;
	background-position: left center;
}

.link-url {
	@extend %icon-link;
	background-image: url('#{$icon-path}/globe.png');
	background-size: 16px 16px;
	background-position: left center;
}
