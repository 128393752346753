.search__tabs {
	@include row;
    width: 100%;
    padding: 0;
    margin: 0;
    flex: 0 0 100%;
    align-items: flex-end;

	li {
		list-style: none;
		flex: 1;
		margin-right: 10px;
		overflow: hidden;

		&:last-child {
			margin-right: 0;
		}

    	a {
    		@include box-sizing(border-box);
			border-top-right-radius: 6px;
			border-top-left-radius: 6px;
			background: $red;
			display: block;
			padding: 10px 15px;
			font-size: 16px;
    		color: $white;
    		text-decoration: none;
    		width: 100%;
    		margin-top: 5px;

    		@include respond-to(1000) {
    			font-size: 14px;
    		}
    	}
	}

	.selected {
		background-color: $white;
		color: $red;
		font-weight: $f-bold;
		padding-bottom: 15px;
		margin-top: 0;
	}

	@include respond-to(786) {
		display: none;
	}
}

.search__wrapper {
	z-index: 10;
	width: 100%;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	margin-bottom: 10px;

	@include respond-to(786) {
		border-radius: 0;
	}
}

.search__mobile-toggle {
	background-color: $red;
	color: white;
	font-weight: bold;
	padding: 12px 25px;
	margin-bottom: 12px;

	position: relative;

	@include respond-to-min(786) {
		display: none;
	}

	&.is-open {
		margin-bottom: 0;
	}

	&:after {
		content: "◂";
		position: absolute;
		right: 20px;
		top: 10px;
	}

	&.is-open:after {
		content: "▾";
	}
}

.search-results {
	background-color: $white;
	display: flex;
	flex-direction: row;
	padding: 20px 10px;
	margin-bottom: 15px;

	@include respond-to(786) {
		display: none !important;
		flex-direction: column;

		&.is-visible {
			display: flex !important;
		}

		.search-results__filterbox {
			max-width: none;
			margin-bottom: 10px;
		}

		.search__content {
			padding: 0;
			width: 100%;
		}
	}

	&--promoted {
		margin: 0;
		padding: 0;
		background-color: white;
		list-style: none;

		li {
			padding: 20px;
		}

		h2 {
			text-transform: uppercase;
			color: $red;
			font-weight: $f-bold;
			font-size: 22px;
			margin: 0 0 12px 0;
		}
	}

	&__filterbox {
		background-color: $grey2;
		border-radius: 6px;
		padding: 20px;
		flex: 0 1 25%;

		&__context {
			color: $grey1;
			margin-bottom: 7px;
			display: inline-block;
			font-weight: $f-bold;
			font-size: 15px;
		}

		&__headline {
			color: $red;
			text-transform: uppercase;
			margin: 0 0 20px 0;
		}

		&__label {
			color: $grey1;
			margin-bottom: 7px;
			display: inline-block;
			font-weight: $f-bold;
		}

		&__dropdown {
			appearance: none;
			border: none;
			border-radius: 0;
			font-size: 1em;
			width: 100%;
			background-color: $grey1;
			color: $grey2;
			padding: 10px;
			background-image: url('#{$icon-path}/dropdown-arrow.png');
			background-repeat: no-repeat;
			background-position: 95% 50%;
			margin-bottom: 20px;
		}
	}
}

.search-result__items.slick-initialized {
	margin: auto 30px;

	.teaser-item {
		border: 0;
		
		&:hover {
			background-color: transparent;
		}
	}
}

.search__content {
	color: $grey1;
	padding-left: 10px;
	flex: 0 1 75%;

	&__highlight {
		background-color: $grey2;
		border-radius: 6px;
		padding-top: 10px;
		padding-left: 80px;
		padding-right: 30px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: $f-regular;
		background-image: url('#{$icon-path}/info.png');
		background-position: 20px center;
		background-repeat: no-repeat;
		background-size: 40px auto;

		h2 {
			text-transform: uppercase;
			color: $white;
			font-weight: $f-extrabold;
			font-size: 26px;
			margin: 0 0 7px 0;
		}

		h3 {
			text-transform: uppercase;
			color: $red;
			font-weight: $f-bold;
			font-size: 22px;
			margin: 0 0 12px 0;
		}

		h4 {
			text-transform: uppercase;
			color: $red;
			font-size: 16px;
			margin: 0 0 5px 0;
			font-weight: $f-semibold;
		}
	}
}

.no-results {
	text-align: center;
	font-size: 14px;

	h2 {
		color: $red;
		font-weight: $f-semibold;
		font-size: 36px;
	}

	.most_searched {
		margin-top: 25px;
	}

	.most_searched__headline {
		color: $grey1;
	}
}
