.breadcrumb {
	margin-top: 5px;
    margin-bottom: 10px;
	color: $white;
    font-size: 18px;
    background: rgba(255,255,255,0.5);
    border-radius: 6px;
    padding: 5px 10px;
    display: inline-block;

	&__searchterm {
		color: $red;
        font-weight: $f-extrabold;
	}

    @include respond-to(786) {
        padding: 0 2%;
    }
}
