.teaser-item {
    @include box-sizing(border-box);
    display: flex;
    margin: 0 0 10px;
    padding: 20px;
    border-bottom: 1px solid $grey2;
    transition: .1s all ease-in;

    @include respond-to(600) {
        flex-direction: column;
    }

    &:hover {
        background: $grey2;
        border-radius: 6px;
    }

    &--is-sticky,
    &--sideinfo {
        border: 1px solid $red;
        border-radius: 6px;
        position: relative;
        padding-top: 5px;
    }

    &--address {
        justify-content: flex-start;
        position: relative;

        &:hover {
            background: transparent;
        }
    }

    &__headline,
    &__headline a {
        color: $red;
        font-size: 16px;
        font-weight: $f-semibold;
        margin: 0 0 5px;
        line-height: 1.2;

        small {
            display: block;
            color: black;
        }
    }

    &__text {
        font-size: 14px;
        font-weight: $f-regular;
    }

    &__img {
        height: auto;
        width: auto;
        max-width: 250px;
        margin-right: 20px;
        flex: 0 0 100%;

        img {
            max-width: none;
            max-height: 250px;
            width: auto;
        }

        @include respond-to(600) {
            width: 100%;
            max-width: none;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__body {
        color: $grey1;
    }

    &__address {
        position: relative;

        &-wrapper {
            max-width: 50%;

            @include respond-to(800) {
                max-width: none;
                width: 100%;
            }
        }

        &-tooltip {
            display: none;
            width: 50%;
            right: 0;
            top: 0;
        }

        &-description-headline,
        &-description-headline a {
            color: $red;
            font-size: 14px;
            text-transform: uppercase;
            margin: 0 0 15px;
            font-weight: $f-semibold;
        }

        &-text {
            margin-top: 0;
            color: black;
        }

        &-details {
            font-size: .9em;
        }
    }
}
