.header {
	margin-bottom: 40px;
	display: flex;

	@include respond-to(786) {
		flex-direction: column;
		margin-bottom: 0;
	}

	.logo {
		max-width: 273px;
		flex-grow: 1;

		&__img {
			width: auto;
		}

		&__img-mobile {
			display: none;
		}

		@include respond-to(786) {
			max-width: none;
			text-align: center;
			width: 100%;
			flex-grow: auto;
			background: $white;

			&__img {
				display: none;
			}

			&__img-mobile {
				display: inline-block;
				width: 100px;
				margin-top: 4px;
			}
		}
	}

	.contact {
		@include box-sizing(border-box);
		height: 240px;
		background-color: $white;
		padding: 20px 35px;
		margin-left: 10px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		width: calc(100% - 283px);

		&__headline {
			font-size: 20px;
			color: $grey1;
			font-weight: $f-regular;
			margin-bottom: 13px;
			display: inline-block;

			b {
				font-weight: $f-bold;
			}

			@include respond-to(880) {
				font-size: 18px;
			}


			@include respond-to(786) {
				text-align: center;
				display: block;
			}

			@include respond-to(488) {
				font-size: 12px;
			}
		}

		&__direct {
			float: left;
			display: inline-block;
			margin: 0 60px 0 0;
			padding: 0;
			list-style: none;
			font-weight: $f-bold;
			font-size: 20px;

			li {
				@include v-center-row;
				line-height: 2em;
			}

			a {
				text-decoration: none;
				color: $red;
				margin-left: 10px;

				&:hover {
					text-decoration: underline;
				}
			}

			@include respond-to(1084) {
				margin-right: 40px;
			}

			@include respond-to(1042) {
				margin-right: 20px;
			}

			@include respond-to(768) {
				font-size: 14px;
				width: 100%;
				display: flex;
				align-content: center;
				justify-content: center;

				li {
					display: inline;
					margin: 0 12px;
				}

				span {
					display: none;
				}
			}
		}

		&__officehours {
			float: left;
			margin: 0 80px 0 0;
			padding: 0;
			font-weight: $f-bold;
			font-size: 14px;
			color: $grey1;

			h4 {
				margin: 0;
				font-size: 20px;
				color: $red;
			}

			@include respond-to(1084) {
				margin-right: 60px;
			}

			@include respond-to(1042) {
				margin-right: 30px;
			}

			@include respond-to(921) {
				margin-right: 0;
			}

			@include respond-to(880) {
				display: none;
			}
			
			@include respond-to(786) {
				display: inline-block;
				margin-right: 0;
				width: 100%;
				border-top: 1px solid $grey2;
				padding-top: 10px;
				margin-top: 10px;

				h4 {
					font-size: 14px;
				}

				div {
					display: inline-block;
				}

				div:last-child {
					display: block;
				}
			}
		}

		&__social {
			float: left;
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 14px;

			li {
				@include v-center-row;
				line-height: 2.7em;
			}

			a {
				text-decoration: none;
				color: $grey1;
				font-weight: $f-bold;
				margin-left: 10px;
				display: inline-flex;
				align-items: center;

				&:hover {
					text-decoration: underline;
				}

				span {
					display: inline-block;
					margin-left: 5px;
				}
			}

			// @include respond-to(1104) {
			// 	display: none;
			// }

			@include respond-to(786) {
				display: flex;
				justify-content: center;

				a > span {
					display: none;
				}

				li {
					margin: 0 12px;
				}
			}
		}

		&__wrapper {
			@include respond-to(786) {
				display: flex;
				flex-direction: column;

				.contact__direct {
					order: 1;
				}
				.contact__social {
					order: 2;
				}
				.contact__officehours {
					order: 3;
				}
			}
		}

		@include respond-to(786) {
			height: auto;
			margin: 12px 2%;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			width: 96%;
		}

		@include respond-to(396) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
