.content-page {
	@include box-sizing(border-box);
	background-color: $white;
	width: 100%;
	border-radius: 6px;
	padding: 0 30px 30px;
	margin-bottom: 10px;
	min-height: 300px;

	&__headline {
		font-weight: $f-bold;
		font-size: 20px;
		color: $white;
		background-color: $red;
		margin-left: -30px;
		padding: 15px 30px;
		width: 100%;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		em {
			font-style: normal;
			font-weight: normal;
			font-size: .75em;
			display: block;
			margin-top: -1px;
		}
	}

	&__content {
		@include row;

		@include respond-to(786) {
			@include column;
		}
	}

	&__description {
		flex: 0 1 100%;
		font-size: 14px;
		padding-right: 6%;

		a {
			color: $red;
			text-decoration: underline;
		}

		@include respond-to(786) {
			width: 100%;
			margin-bottom: 15px;
		}
	}

	&__additional-information {
		flex: 0 1 60%;
		padding: 0 0 40px 0;

		img {
			width: auto;
			height: auto;
			max-width: 100%;
		}

		iframe {
			width: 100%;
			min-height: 300px;
			max-height: 600px;
			height: 100%;
		}

		@include respond-to(786) {
			width: 100%;
		}

		&__mvg-link {
			text-align: right;
			overflow: hidden;
			padding: 15px 0 20px;

			img	{
				float: right;
			}
		}
	}
}
