.error {

	&__wrapper {
		width: 50%;
		display: inline-block;
		left: 50%;
		transform: translateX(-50%);
		position: relative;
	}

	&__code {
		font-size: 56px;
		color: $red;
		font-weight: $f-semibold;
		text-align: center;
		display: inline-block;
		width: 100%;
		margin-top: 30px;
	}

	&__text {
		font-size: 36px;
		color: $red;
		font-weight: $f-semibold;
		text-align: center;
		display: inline-block;
		width: 100%;
		text-transform: uppercase;
		line-height: 0.8em;
	}

	&__description {
		font-size: 14px;
		font-weight: $f-bold;
		margin-top: 50px;
		margin-bottom: 30px;
		display: inline-block;
	}
}