.two-column-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@include respond-to(786) {
		@include column;
	}

  .column--50 {
    width: 49.5%;

    @include respond-to(1100) {
      .slider__content {
        @include column;
        width: 100%;
        align-items: center;
      }

      .slider__imgwrapper {
        margin-bottom: 10px;
      }
    }

    @include respond-to(786) {
      width: 100%;
    }
  }


  .column--100 {
    width: 100%;
  }
}
