body {
	font-family: $f-body;
	background: $grey1 url('/static/theme/img/bg-body.png') no-repeat top center;
	background-size: 100% auto;
	width: 100%;
	height: 100%;
	padding-top: 0;
	margin: 0;
	text-align: center;

	@include respond-to(762) {
		background-image: none;
	}
}

.body {
	@include box-sizing(border-box);
	max-width: 1160px;
	width: 100%;
	display: inline-block;
	text-align: left;
	padding: 0 2%;

	@include respond-to(786) {
		padding: 0;
	}
}

a {
	text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.content img {
  display: block;
  margin: auto;
}

.responsive-object {
    position: relative;
}
.responsive-object iframe,
.responsive-object object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
