/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/static/theme/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/static/theme/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/static/theme/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/static/theme/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/static/theme/fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
        url('/static/theme/fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/static/theme/fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/static/theme/fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('/static/theme/fonts/open-sans-v17-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
        url('/static/theme/fonts/open-sans-v17-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/static/theme/fonts/open-sans-v17-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-600italic.woff') format('woff'), /* Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/static/theme/fonts/open-sans-v17-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/static/theme/fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('/static/theme/fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/static/theme/fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/static/theme/fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/static/theme/fonts/open-sans-v17-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
        url('/static/theme/fonts/open-sans-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/static/theme/fonts/open-sans-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-800.woff') format('woff'), /* Modern Browsers */
        url('/static/theme/fonts/open-sans-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/static/theme/fonts/open-sans-v17-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
