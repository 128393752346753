.footer {
	@include box-sizing(border-box);
	color: $white;
	display: inline-block;
	font-size: 14px;
	width: 100%;

	&__left {
		margin-top: 20px;
		display: inline-block;

		@include respond-to(704) {
			width: 100%;
			float: none;
			position: relative;
			top: 110px;
			margin: 0;
		}
	}

	&__nav {
		margin-top: 60px;
		display: inline-block;
		float: right;

		li {
			display: inline-block;

			a {
				color: $white;
				font-size: 14px;
				text-decoration: underline;
			}
		}

		@include respond-to(704) {
			width: 100%;
			float: none;
			position: relative;
			margin: 0;
			text-align: center;
			padding: 0;
			top: -110px;
		}
	}

	&__rss {
		margin-top: 20px;
		text-align: right;

		a {
			color: $white;
			text-decoration: none;

			img {
				padding-right: 10px;
			}
		}

		@include respond-to(704) {
			position: absolute;
			top: -40px;
			margin: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	@include respond-to(786) {
		padding: 0 2%;
	}

	@include respond-to(704) {
		position: relative;
	}
}