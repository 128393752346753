.alert {
  background-color: rgba($grey1, 0.2);
  color: white;
  padding: 10px;
  margin: 40px 0;
  display: none;
  width: 100%;

  &.is-success {
    background-color: $c-success;
  }

  &.is-info {
    background-color: $c-info;
  }

  &.is-error {
    background-color: $c-error;
  }
}
