.slider {
	@include box-sizing(border-box);
	background-color: $white;
	margin-bottom: 10px;
	border-radius: 6px;
	padding: 30px;

	&--single-content {
		.slider__content {
			@include row;
			max-width: 800px;
			position: relative;
			margin: auto;
			@include respond-to(786) {
				 @include column;
				align-items: center;
			}
		}

		.slider__imgwrapper {
			margin-right: 20px;

			@include respond-to(786) {
				margin-right: 0;
			}
		}

		.slick-track {
			display: flex;
			align-items: center;
		}
	}

	&--multiple-content {
		.slider__content {
			text-align: center;
			width: 100%;
		}

		.slider__imgwrapper {
			width: 100%;
			margin: 0;

			img {
				max-height: 150px;
			}
		}
	}

	&__headline {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 15px;
		margin-top: 0;
		font-weight: $f-bold;

		a {
			color: #000;
			text-decoration: none;
		}
	}

	&__content {
		@include box-sizing(border-box);
		padding: 0 20px;

		&__headline {
			color: $red;
			font-size: 16px;
			margin-top: 0;
			margin-bottom: 15px;
		}
	}

	&__imgwrapper {
		text-decoration: none;
        margin-bottom: 20px;
	}

	&__text {
		color: $grey1;
		font-size: 14px;
		display: inline-block;
		width: 100%;
	}

	&__caption {
		font-weight: $f-bold;
		color: $grey1;
		text-align: center;
		width: 100%;
		margin: 10px 0;
	}
	@include respond-to(786) {
		border-radius: 0;
	}
}
// vendor stuff
.slick {
	&-slide {
		outline: 0;
		display: flex !important;
		align-items: center;
		float: none !important;
		height: auto !important;
	}

	&-slider {
		height: calc(100% - 30px);
	}

	&-arrow {
		color: $red;
		cursor: pointer;
		background-color: transparent;
		border: none;
		position: absolute;
		text-decoration: none;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0;
		line-height: 0;
		outline: none;
	}

	&-arrow::before {
		display: block;
		font-size: 50px;
		width: 30px;
		height: 50px;
		line-height: 0.8;
	}

	&-prev {
		left: -30px;
	}

	&-prev::before {
		content: '«';
	}

	&-next {
		right: -30px;
	}

	&-next::before {
		content: '»';
	}

	&-track {
		display: flex !important;
		flex-direction: row;
		align-items: stretch;

		img {
			max-height: 200px;
			max-width: 250px;
			width: auto;
			height: auto;
		}
	}
}
