form {
  border: 0;

  &.has-success {
    [readonly] {
      background-color: rgba(white, .2);
      border: 2px dotted rgba($grey1, .2);
    }

    input[type=submit] {
      display: none;
    }
  }
}

.is-loading {
  cursor: wait;
  pointer-events: none;
}

input {
  display: block;

  &[type=email],
  &[type=text] {
    padding: 7px;
    box-sizing: content-box;
  }

  &[type=checkbox] {
    border: 0;
    width: 20px;
    height: 20px;
    background-color: white;
  }

  &[type=submit] {
    cursor: pointer;
    border: 1px solid $red;
    background-color: $red;
    color: white;
    font-weight: bold;
    font-size: 15px;
    box-sizing: content-box;
    margin-top: 10px;
    transition: all 0.2s ease;

    &:focus {
      border: 1px solid $dark-red;
      background-color: $red;
    }
  }
}

label {
  display: block;
  color: $red;
  margin: 15px auto 5px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

textarea {
  height: 120px;
}

input,
textarea {
  border: 1px solid $grey2;
  padding: 10px;
  line-height: 1.4;
  background-color: $grey2;
  display: block;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    outline: 0;
    border: 1px solid $grey1;
    background-color: #fff;
  }

  &.has-error {
    border: 2px dotted $c-error;
    background-color: rgba($c-error, .2);
  }
}
